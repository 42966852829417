.navbar__container {
    max-width: 96%;
    margin: 0 auto;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
  
  .navbar__wrapper {
    height: 60px;
    background-color: #f4e0fb;
    position: relative;
  }
  
  .menu__icon {
    display: none;
    font-size: 24px;
  }
  
  .logo__icon {
    cursor: pointer;
  }
  
  .navbar__content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
  }
  
  .navbar__elements ul {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
  }
  
  .navbar__elements ul li:not(:last-child) {
    margin-right: 60px;
  }
  
  .navbar__elements ul a {
    font-size: 16px;
    font-weight: 400;
    color: #2f234f;
    text-decoration: none;
  }
  
  .navbar__elements ul a.active {
    color: #574c4c;
    font-weight: 500;
    position: relative;
  }
  
  .navbar__elements ul a.active::after {
    content: "";
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #574c4c;
  }
  
  .user__dropdown {
    position: relative;
    cursor: pointer;
  }
  
  .user__dropdown span {
    margin-left: 30px;
    font-size: 24px;
  }
  
  .user__dropdown__content {
    display: none;
    position: absolute;
    background-color: #f4e0fb;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    z-index: 1;
  }
  
  .user__dropdown__content a {
    color: #2f234f;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .user__dropdown__content a:hover {
    background-color: #f0f0f0;
  }
  
  .user__dropdown:hover .user__dropdown__content {
    display: block;
    width: 120px;
    right: 10px;
    top: 30px;
  }
  
  @media (max-width: 768px) {
    .navbar__elements ul li:not(:last-child) {
      margin-right: 30px;
    }
  }
  
  @media (max-width: 600px) {
    .menu__icon {
      display: block;
      cursor: pointer;
    }
  
    .navbar__elements {
      position: absolute;
      right: 0;
      top: 60px;
      background-color: #f4e0fb;
      width: 0px;
      height: calc(100vh - 60px);
      transition: all 0.3s ease-in;
      overflow: hidden;
    }
  
    .navbar__elements.active {
      width: 270px;
    }
  
    .navbar__elements ul {
      display: flex;
      flex-direction: column;
    }
  
    .navbar__elements ul li {
      margin-right: unset;
      margin-top: 22px;
    }
  }
  