.verbs-container {
  padding: 20px;
  border: 3px solid #ccc;
  border-radius: 25px;
  margin-bottom: 20px;
  background-color: rgb(245, 220, 239);
  }
  
  .verbs-container h2 {
    margin-bottom: 10px;
  }
  
  .verbs-list {
    display: flex;
    flex-wrap: wrap;
  }
  
  .verb {
    width: calc(50% - 20px);
    margin-right: 20px;
    margin-bottom: 20px;
  }
  
  .verb h3 {
    margin-bottom: 10px;
  }
  