.tenses-container {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  
  .tenses-container h2 {
    margin-bottom: 10px;
    font-size: 34px;
  }
  
  .tenses-list {
    display: flex;
    flex-wrap: wrap;
  }

  .tenses-list h3 {
    font-size: 22px;
    color: darkblue;
  }
  
  .tense {
    width: calc(33.33% - 55px);
    margin-right: 20px;
    margin-bottom: 20px;
    background-color: mintcream;
    padding: 10px;
    border: 2px solid black;
    border-radius: 15px;
  }
  
  .tense h3 {
    margin-bottom: 10px;
  }
  
  .tense ul {
    list-style-type: none;
    padding: 0;
  }
  
  .tense ul li {
    margin-bottom: 5px;
  }
  