.nouns-container {
  padding: 20px;
  border: 3px solid #ccc;
  border-radius: 25px;
  margin-bottom: 20px;
  background-color: rgb(255, 232, 232);
  }
  
  .nouns-container h2 {
    margin-bottom: 10px;
  }
  
  .nouns-list {
    display: flex;
    flex-wrap: wrap;
  }
  
  .noun {
    width: calc(50% - 20px); /* Adjust width as needed */
    margin-right: 20px;
    margin-bottom: 20px;
  }
  
  .noun h3 {
    margin-bottom: 10px;
  }
  