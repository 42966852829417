.learn__page {
    padding: 20px;
    text-align: center;
}

.learn__page h1 {
    font-size: 40px;
}

.learn__page > div {
    display: flex;
    flex-wrap: wrap;
}

.lessons__card {
    height: 250px;
    width: 200px;
    border: 3px solid black;
    background-color: #cbffe5;
    border-radius: 25px;
    cursor: pointer;
    margin: 30px;
}

.lessons__card p {
    font-size: 32px;
    height: 40%;
}

.lessons__card:hover {
    box-shadow: 10px 10px;
}

.lessons__card button {
    width: 100px;
    padding: 15px;
    border-radius: 50px;
    background-color: pink;
    border: 3px solid black;
    font-size: 16px;
    cursor: pointer;
}